<template>
    <div>
        <div class="container mt-3">
            <form action="">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card bg-light">
                            <div class="card-header">
                                <transition name="fade" mode="out-in">
                                    <h5 v-if="mode !== 'show'"><i class="bi bi-person-lines-fill mr-3"></i>{{$t('account.infos-personnelles')}}</h5>
                                    <h5 v-else><i class="bi bi-person-lines-fill mr-3"></i>{{$t('account.infos-personnelles')}}</h5>
                                </transition>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4 mt-3">
                                        <label for="last_name">{{$t('account.nom')}}*</label>
                                        <input type="text" class="form-control" id="last_name" v-model="form.last_name" required :disabled="mode=== 'show'">
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors.last_name">
                                                <div v-for="error in errors.last_name" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <label for="first_name">{{$t('account.prenom')}}*</label>
                                        <input type="text" class="form-control" id="first_name" v-model="form.first_name" required :disabled="mode=== 'show'">
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors.first_name">
                                                <div v-for="error in errors.first_name" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <label for="matricule">{{$t('account.matricule')}}*</label>
                                        <input type="number" class="form-control" id="matricule" v-model="form.matricule" :disabled="mode=== 'show'">
                                        <transition name="fade" mode="out-in">
                                            <div v-if="errors && errors.matricule">
                                                <div v-for="error in errors.matricule" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 mt-3">
                                        <label>{{$t('account.gsm')}}*</label>
                                        <phone-mask-input v-if="type === 'create' || cellphone"
                                              :disabled="mode=== 'show'"
                                              ref="cellphoneInput"
                                              :value="type !== 'create' ? cellphone : ''"
                                              required
                                              showFlag
                                              autoDetectCountry
                                              @onValidate="validateCellPhone"
                                              wrapperClass="input-flag-wrapper"
                                              :inputClass="cellphoneIsValid ? 'form-control input-flag' : 'form-control input-flag input-flag-invalid'"
                                              flagClass="flag"
                                        />
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors['cellphone_number']">
                                                <div v-for="error in errors['cellphone_number']" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <label>{{$t('account.telephone')}}</label>
                                        <phone-mask-input
                                                          :disabled="mode=== 'show'"
                                                          ref="phoneInput"
                                                          :value="type !== 'create' ? phone : ''"
                                                          showFlag
                                                          @onValidate="validatePhone"
                                                          wrapperClass="input-flag-wrapper"
                                                          :inputClass="phoneIsValid? 'form-control input-flag' : 'form-control input-flag input-flag-invalid'"
                                                          flagClass="flag"
                                        />
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors['telephone_number']">
                                                <div v-for="error in errors['telephone_number']" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <div class="row" v-if="config.etablissement.user_address">
                                    <div class="col-md-2 mt-3">
                                        <label for="number">Numéro*</label>
                                        <input type="number" id="number" class="form-control" v-model="form.number" :disabled="mode=== 'show'">
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors['number']">
                                                <div v-for="error in errors['number']" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-5 mt-3">
                                        <label for="street">{{$t('account.rue')}}*</label>
                                        <input type="text" id="street" class="form-control" v-model="form.street"  :disabled="mode=== 'show'">
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors['street']">
                                                <div v-for="error in errors['street']" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-5 mt-3">
                                        <label for="street_complement">{{$t('account.complement-adresse')}}</label>
                                        <input type="text" id="street_complement" class="form-control" v-model="form.street_complement" :disabled="mode=== 'show'">
                                    </div>
                                </div>
                                <div class="row" v-if="config.etablissement.user_address">
                                    <div class="col-md-2 mt-3">
                                        <label for="postal_code">{{$t('account.code-postal')}}*</label>
                                        <input type="text" id="postal_code" class="form-control" v-model="form.postal_code" :disabled="mode=== 'show'">
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors['postal_code']">
                                                <div v-for="error in errors['postal_code']" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-5 mt-3">
                                        <label for="city">{{$t('account.ville')}}*</label>
                                        <input type="text" id="city" class="form-control" v-model="form.city" :disabled="mode=== 'show'">
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors['city']">
                                                <div v-for="error in errors['city']" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-5 mt-3">
                                        <label for="city">{{$t('account.pays')}}*</label><!--@input="data=>form.country = data.label"-->
                                        <v-select :clearable="false" :options="pays"  @input="onSelectCountry"  :disabled="mode=== 'show'" :value="form['country']"></v-select>
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors['country']">
                                                <div v-for="error in errors['country']" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="config.etablissement.mail_reminder || config.etablissement.sms_reminder" class="row">
                    <div class="col-md-12">
                        <div class="card bg-light">
                            <div class="card-header">
                                <h5><i class="bi bi-gear-fill mr-3"></i><span v-html="$t('account.preferences')"></span></h5>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div v-if="config.etablissement.mail_reminder" class="col-md-4 mt-3">
                                        <div class="form-check form-switch clickable">
                                            <input :disabled="mode=== 'show'" class="form-check-input clickable" type="checkbox" id="mail_reminder" v-model="form.mail_reminder" >
                                            <label class="form-check-label clickable ms-4" for="mail_reminder">{{$t('account.autorisation-mail')}}</label>
                                        </div>
                                    </div>
                                    <div v-if="config.etablissement.sms_reminder" class="col-md-4 mt-3">
                                        <div class="form-check form-switch clickable">
                                            <input :disabled="mode=== 'show'" class="form-check-input clickable" type="checkbox" id="sms_reminder" v-model="form.sms_reminder" >
                                            <label class="form-check-label clickable ms-4" for="sms_reminder">{{$t('account.autorisation-sms')}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card bg-light">
                            <div class="card-header">
                                <h5><i class="bi bi-lock-fill mr-3"></i>{{$t('account.donnees-connexion')}}</h5>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4 mt-3">
                                        <label for="register-email">{{$t('account.email')}}*</label>
                                        <input type="text" class="form-control" id="register-email" v-model="form.email" required :disabled="mode=== 'show' || mode ==='edit'">
                                        <transition name="fade" mode="out-in">
                                            <div v-if="errors && errors.email">
                                                <div v-for="error in errors.email" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <label for="register-password">{{$t('account.password')}}*</label>
                                        <input type="password" class="form-control" id="register-password"
                                               :disabled="mode=== 'show'"
                                               v-model="form['password']" required
                                               :autocomplete="mode ==='edit' ? 'new-password' : 'true'"
                                               :placeholder="mode === 'edit' ? $t('account.laisser-vide') : null"
                                        >
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors.password">
                                                <div v-for="error in errors.password" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <label for="password_confirmation">{{$t('account.password-confirmation')}}*</label>
                                        <input type="password" class="form-control" id="password_confirmation"
                                               :disabled="mode=== 'show'"
                                               :autocomplete="mode ==='edit' ? 'new-password' : 'true'"
                                               v-model="form['password_confirmation']"
                                               :placeholder="mode === 'edit' ? $t('account.laisser-vide') : null"
                                        >
                                        <transition name="slide" mode="out-in">
                                            <div v-if="errors && errors['password_confirmation']">
                                                <div v-for="error in errors['password_confirmation']" class="invalid" >
                                                    {{error}}
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row mt-3">
                                    <div class="col-md-12 text-right">
                                        <button v-if="mode === 'edit'" :disabled="loading"  class="btn btn-sm btn-danger mr-2" @click.prevent="onDismiss">
                            <span ><i class="bi bi-x mr-3"></i>Annuler
                            </span>
                                        </button>
                                        <button v-if="mode === 'show'" :disabled="loading"  class="btn btn-sm btn-success" @click.prevent="mode = 'edit'">
                                            <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                            <span v-if="loading" >{{$t('global.chargement')}}</span>
                                            <span v-if="!loading"><i class="bi bi-pencil-square mr-2"></i>{{$t('global.modifier')}}
                            </span>
                                        </button>
                                        <button v-if="mode === 'edit' || mode === 'create'" :disabled="loading" type="submit" class="btn btn-sm btn-primary" @click="onSubmit">
                                            <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                            <span v-if="loading" >{{$t('global.chargement')}}</span>
                                            <span v-if="!loading"><i class="bi bi-check2 mr-2"></i>{{$t('global.enregistrer')}}
                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import authApi from '../../../api/auth'
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "../../../store/namespaces";
    import PhoneMaskInput from  "vue-phone-mask-input";
    export default {
        name: "ProfileForm",
        components: {
            PhoneMaskInput
        },
        props: {
            type: {
                type: String,
                required: false,
                default() {
                    return 'create'
                }
            }
        },
        data() {
            return {
                loading: false,
                form: {
                    last_name: null,
                    first_name: null,
                    cellphone_prefix: null,
                    cellphone_number: null,
                    telephone_prefix: null,
                    telephone_number: null,
                    address: null,
                    app_id: Vue.$config.etablissement.id,
                    userId: null,
                    country: null
                },
                cellphone: null,
                phone: null,
                phoneIsValid: true,
                cellphoneIsValid: true,
                errors: [],
                mode: null,
                config: Vue.$config,
                pays: [
                    {
                        label: 'Luxembourg'
                    },
                    {
                        label: 'Belgique'
                    },
                    {
                        label: 'France'
                    },
                    {
                        label: 'Allemagne'
                    },
                ]
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
                getApiToken: namespaces.auth + 'getApiToken',
            }),
        },
        methods: {
            ...mapActions({
                setUser: namespaces.auth + 'setUser',
            }),
            async onSubmit(e) {
                e.preventDefault()
                if(!this.cellphoneIsValid) return
                this.loading = true
                try {
                    let res = null
                    if(this.mode === 'create') {
                        res = await  authApi.signup(this.form)
                    }else {
                        res = await authApi.updateAccountDetails(this.form)
                    }

                    Vue.$toast.open({
                        message: this.mode === 'create' ? this.$t('account.inscription-reussie') : this.$t('account.compte-mis-a-jour'),
                        type: 'success',
                    });
                    this.form.password = null
                    this.form.password_confirmation = null
                    this.mode = 'show'
                    if(this.getUser) {
                        let res = await authApi.getAccountDetails(this.getUser.id, this.getUser.api_token)
                        await this.setUser(res.data)
                    }
                    this.errors = null
                }catch (e) {
                    this.errors = e.response.data.errors
                    let string = ''
                    for (const [key, value] of Object.entries(this.errors)) {
                        value.forEach(m=> {
                            string += '-' + m + '<br>'
                        })
                    }
                    Vue.$toast.open({
                        message: this.$t('account.verification-donnees-saisies') + string,
                        type: 'error',
                    });
                }
                this.loading = false
            },
            onSelectCountry(e) {
                this.form['country'] = e.label
            },
            splitNumber(number, segment = 0){
                let splitted = number.split(' ');
                if(splitted) return splitted[segment];
                else return false
            },
            onDismiss() {
                delete this.form.password
                delete this.form.password_confirmation
                this.init()
                this.mode = 'show'
            },
            async init() {
                if(this.mode === 'show' || this.mode === 'edit') {
                    authApi.getAccountDetails(this.getUser.id, this.getApiToken).then(res=> {
                        this.form.last_name = res.data.last_name
                        this.form.first_name = res.data.first_name
                        this.form.matricule = res.data.matricule
                        this.form.email = res.data.email
                        this.form.mail_reminder = res.data.mail_reminder
                        this.form.sms_reminder = res.data.sms_reminder
                        this.form.address = res.data.address
                        this.form['city'] = res.data.address ? res.data.address.city : null
                        this.form['postal_code'] = res.data.address ? res.data.address.postal_code : null
                        this.form['street_complement'] = res.data.address ? res.data.address.street_complement : null
                        this.form['number'] = res.data.address ? res.data.address.number : null
                        this.form['street'] = res.data.address ? res.data.address.street : null
                        this.form['country'] = res.data.address ? res.data.address.country : null
                        this.form.cellphone_number = res.data.cellphone_number ? this.splitNumber(res.data.cellphone_number, 1) : null
                        this.form.cellphone_prefix = res.data.cellphone_number ? this.splitNumber(res.data.cellphone_number) : null
                        this.cellphone =  res.data.cellphone_number ? res.data.cellphone_number.replace(' ', '') : null

                        this.form.telephone_number = res.data.telephone_number ? this.splitNumber(res.data.telephone_number, 1) : null
                        this.form.telephone_prefix = res.data.telephone_number ? this.splitNumber(res.data.telephone_number) : null
                        this.phone = res.data.telephone_number ? res.data.telephone_number.replace(' ', '') : ''

                        this.form.userId = this.getUser ? this.getUser.id : null
                    })
                }
            },
            validatePhone(e) {
                if(e.number === '') {
                    this.phoneIsValid = true
                    this.errors['telephone_number'] = null
                }else {
                    this.phoneIsValid = e.isValidByLibPhoneNumberJs
                    if(this.phoneIsValid === true) {
                        this.form.telephone_prefix = this.$refs.phoneInput.$data.countryCode
                        this.form.telephone_number = e.number.replace(this.form.telephone_prefix, '')
                        this.errors['telephone_number'] = null
                    }else {
                        this.errors['telephone_number'] = [this.$t('account.format-numero-telephone-invalide')]
                    }
                }
            },
            validateCellPhone(e) {
                this.cellphoneIsValid = e.isValidByLibPhoneNumberJs
                if(this.cellphoneIsValid === true) {
                    this.form.cellphone_prefix = this.$refs.cellphoneInput.$data.countryCode
                    this.form.cellphone_number = e.number.replace(this.form.cellphone_prefix, '')
                    this.errors['cellphone_number'] = null
                }else {
                    this.errors['cellphone_number'] = [this.$t('account.format-numero-telephone-invalide')]
                }
            }

        },
        mounted() {
            this.mode = this.type
            this.init()
        }
    }
</script>

<style >
.input-flag-wrapper {
    position:relative;
}
.input-flag-wrapper .flag {
    position: absolute;
    right: 5px;
    top: 50%;
    transform:scale(.5) translateY(-50%)!important;
}
    .input-flag-invalid {
        border: 1px solid #dc3545;
    }
</style>
